import React, { useEffect } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { Link } from "gatsby";
import HeaderExpanded from "../components/HeaderExpanded/HeaderExpanded";
import "../styles/about.scss";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = (
    <div>
      Our Story:
      <br />A New Zealand AgTech Company
    </div>
  );
  const image =
    process.env.GATSBY_S3_BUCKET_ASSETS + "images/apple_tree_closeup.jpg";

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | About</title>
        <meta
          name="description"
          content="HortPlus is a New Zealand-based agricultural technology firm. We
              have been delivering digital solutions to the primary sector for
              over fifteen years"
        />
      </Helmet>
      <HeaderExpanded title={title} image={image} />
      <Container className="my-5">
        <Row className="d-flex justify-content-start pb-2">
          <Col>
            <h6 className="hp-font-28 pb-2">
              HortPlus is a New Zealand-based agricultural technology firm. We
              have been delivering digital solutions to the primary sector for
              over fifteen years.
            </h6>
            <p>
              HortPlus was founded in the sunny Hawke's Bay, one of the largest
              growing regions in New Zealand. We build and provide digital
              solutions to clients and organisations nationally and
              internationally from our offices in Hastings and Wellington.
            </p>
          </Col>
        </Row>
        <hr></hr>
        <Row className="d-flex justify-content-center align-items-center pt-4">
          <Col md={7} className="mb-5">
            <div className="pb-3">
              <p className="hp-font-22">
                <b>Connecting Data to the Real World</b>
              </p>
              <p>
                We believe in the power of data but we also know that in the
                real world it is important to validate systems with input and
                feedback from actual hardware in the ground and from grower's
                first-hand experience.
              </p>
              <p>
                We maintain close relationships with growers, researchers, and
                agronomists to help us understand their needs in the field and
                in the lab, and we maintain a comprehensive network of
                scientific and research-grade weather stations throughout the
                country that are calibrated and monitored regularly.
              </p>
            </div>
            <div className="pb-3">
              <p className="hp-font-22">
                <b>A Bridge Between Science and Growers</b>
              </p>
              <p>
                We have developed trusted relationships with science and
                technical leaders in a range of fields and have the expertise to
                bring research extension and knowledge to market in a format
                that is both powerful and easy to use.
              </p>
            </div>
            <div className="pb-3">
              <p className="hp-font-22">
                <b>Trusted by Industry and Growers to Deliver</b>
              </p>
              <p>
                We have been trusted to build time-sensitive solutions that are
                critical to growers operations and we have successfully worked
                on ongoing projects in a range of industries that have evolved
                and developed over multiple seasons.
              </p>
            </div>
            <div className="pb-3">
              <p className="hp-font-22">
                <b>Sustainability Focussed</b>
              </p>
              <p>
                <Link to="/sustainability">Sustainability</Link> and improved
                resource usage is a driving force behind our platform and our
                solutions have a direct connection with sustainable practices
                and improvement.
              </p>
            </div>
            <div className="pb-3">
              <p className="hp-font-22">
                <b>Built in New Zealand with a Global Outlook</b>
              </p>
              <p>
                We are an independent company with a desire for the long term
                success of our clients. Our{" "}
                <Link to="/technology">technology</Link> is built in New
                Zealand.
              </p>
            </div>
          </Col>
          <Col md={5} className="pl-md-5">
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                "images/walking_holding_onions.jpg"
              }
              alt="Field walk"
              fluid
            />
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="hp-page hp-page--blue-grey d-flex flex-column align-items-center"
      >
        <Row noGutters>
          <Col>
            <Container as="h2" className="hp-title pb-md-5 text-center">
              Team
            </Container>
            <Container className="pb-md-5">
              <Row className="mb-3">
                <Col className="text-center p-4">
                  <h5>Mike Barley</h5>
                  Managing Director
                </Col>
                <Col className="text-center p-4">
                  <h5>Lesley Kersey</h5>
                  Managing Director &amp; Accounts
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="text-center p-4">
                  <h5>Cody Ellingham</h5>
                  Product Designer &amp; Business Development
                </Col>
                <Col className="text-center p-4">
                  <h5>Liam West</h5>
                  Development Team
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="text-center p-4">
                  <h5>Julia Kurganskaia</h5>
                  Project Management &amp; Development Team
                </Col>
                <Col className="text-center p-4">
                  <h5>Bailey Jewell</h5>
                  Development Team
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="text-center p-4">
                  <h5>Thomas Rainford</h5>
                  Development Team
                </Col>
                <Col className="text-center p-4">
                  <h5>Louis Cooper</h5>
                  Development Team
                </Col>
              </Row>
              <Row>
                <Col className="text-center p-4">
                  <h5>James Heffield</h5>
                  Communications
                </Col>
                <Col className="text-center p-4">
                  <h5>Bryn Thompson</h5>
                  Product Development
                </Col>
              </Row>
              <Row>
                <Col className="text-center p-4">
                  <Button as={Link} to={"/jobs"}>
                    Join Us
                  </Button>
                </Col>
              </Row>
            </Container>
            <Container as="h2" className="hp-title pb-md-5 text-center mt-3">
              Industry Partners
            </Container>
            <p className="d-block mb-5 text-center">
              HortPlus is proud to be part of a network of innovative companies
              helping New Zealand growers.
            </p>
            <Container className="pb-md-5">
              <Row>
                <Col className="text-center p-4">
                  <a href="https://www.fruitionhort.com/">
                    <Image
                      src={
                        process.env.GATSBY_S3_BUCKET_ASSETS +
                        "brands/fruition_logo_new.svg"
                      }
                      alt="Fruition Horticulture"
                      width="300"
                      fluid
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
